@import "../../../styles/theme.scss";

.neuroButton {
  border: 0;
  font-size: $ruler;
  border-radius: $ruler / 2;
  padding: $ruler;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  font-weight: 600;
  box-shadow: -5px -5px 10px $color-white,  5px 5px 10px $color-shadow;
  
  &:hover {
    box-shadow: -2px -2px 5px $color-white, 2px 2px 5px $color-shadow;
  }
  
  &:active {
    box-shadow: inset 1px 1px 2px $color-shadow, inset -1px -1px 2px $color-white;
  }
  
  .icon {
    margin-right: $ruler/2;
  }

  &.rounded {
    border-radius: $ruler*20;
  }
  
  &.unit {
    border-radius: $ruler/2;
    line-height: 0;
    width: $ruler*3;
    height: $ruler*3;
    display:inline-flex;
    justify-content: center;
    align-items:center;
    margin: 0 $ruler/2;
    font-size: $ruler*1.2;
    
    .icon {
      margin-right: 0; 
    }
  }
}