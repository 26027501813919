.input {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  align-items: flex-start;
  > .content {
    display: flex;
    align-items: center;
    width: 100%;

    > input {
      width: calc(100% - 10px);
      border: none;
      outline: none;
      padding: 0px;

      &.inactive {
        text-decoration: line-through;
      }

      &:read-only {
        cursor: pointer;
      }
    }
  }
}
