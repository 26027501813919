.dropdown {
  display: flex;
  flex-direction: column;
  position: relative;

  &.fullSize {
    width: unset !important;
  }

  .header {
    display: flex;
    align-items: center;
    cursor: pointer;

    &.multi {
      position: relative;
      > .input {
        > .content {
          > .values {
            position: absolute;
            display: flex;
            width: calc(100% - 60px);
            overflow-x: auto;

            > .button {
              border: unset;
              padding: 10px;
              background: rgb(197, 197, 197);
              color: rgb(92, 92, 92);
              height: 7px;
              margin: 3px;
              border-radius: 5px;
              white-space: nowrap;

              &::after {
                content: "x";
                color: black;
                font-weight: bold;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }

    > .input {
      width: 100%;
      > .content {
        > input {
          width: calc(100% - 50px);
        }
        > .delete {
          border: unset;
          color: red;
          position: relative;
          padding: 0px;

          &::before {
            display: flex;
            content: "X";
            font-weight: bold;
            border-radius: 100%;
            background: rgb(243, 243, 243);
            width: 20px;
            height: 20px;
            justify-content: center;
            align-items: center;
          }
        }

        > .caret {
          margin-right: 5px;
          outline: none;
          border: none;
          position: relative;
        }
      }
    }
  }

  > .content {
    position: absolute;
    width: 100%;
    background: white;
    top: 48px;
    margin-top: 3px;
    z-index: 1;
    border-radius: 10px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    overflow-y: auto;
    max-height: 140px;
    min-height: 30px;
    box-sizing: content-box;
    border: 1px solid rgb(209, 207, 207);

    &.noLabel {
      top: 28px;
    }

    > .button {
      outline: none;
      border: none;
      padding: 10px;
      width: 100%;
      justify-content: flex-start;

      > span {
        text-align: left;
      }

      &:hover {
        background: rgb(216, 216, 216);
      }
    }

    > .customContent {
      padding: 10px;
    }
  }
}
