$color-shadow: #7a7a7a;
$color-white: #FFF;

.backButton {
  border: unset;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  justify-content: center;
  box-shadow: -5px -5px 10px $color-white,  5px 5px 10px $color-shadow;
  
  &:hover {
    box-shadow: -2px -2px 5px $color-white, 2px 2px 5px $color-shadow;
  }
  
  &:active {
    box-shadow: inset 1px 1px 2px $color-shadow, inset -1px -1px 2px $color-white;
  }

  &::before {
    display: flex;
    font-size: 25px;
    opacity: 0.3;
  }
}
