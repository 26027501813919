.overlayMenu{
    flex-direction: column;
    display: none !important;

    >.content{
        width: unset !important;
        min-width: unset !important;
        min-height: unset !important;
        position: absolute !important;
        right: 15px !important;
        top: 40px !important;

        >.header{
            display: none !important;
        }

        >.items{
            padding: 8px 10px !important;
            padding-right: 0px;

            >*{
                margin-top: 3px;
            }
        }
    }
}

@media (orientation: portrait) {
    .overlayMenu {
        display: flex !important;
    }
}