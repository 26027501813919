@import "../../../../styles/mixins.scss";

.pagination {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  background: #efefef;
  padding: 10px 6px;

  .pag {
    display: flex;
    align-items: center;
    @include margin_items_horizontal(5px);

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    > span {
      white-space: nowrap;
    }
  }

  .center {
    width: 50%;
    @include margin_items_horizontal(20%);
    .input,
    .dropdown {
      width: 80px;
    }

    .dropdown {
      > .content {
        z-index: 1;
      }
    }

    .input {
      input {
        text-align: center;
      }
    }
  }

  > .button {
    border: 1px solid #cccccc;
    padding: 5px 30px;
    border-radius: 30px;
    background: white;
  }
}
