.checkbox {
  display: flex;
  flex-direction: column;

  &.checked {
    > .content {
      > .btn {
        background: #66615b;
      }
    }
  }

  > .content {
    display: flex;
    align-items: center;

    > .btn {
      border: unset;
      background: #aaa7a4;
      width: 25px;
      height: 25px;
      border-radius: 30%;
      color: white;
      padding: 3px;

      &.type2 {
        background: unset;
        color: black;
        margin-right: -5px;
        margin-left: -6px;
        padding-left: 0;
        transform: scale(1.4);

        > svg {
          position: relative;
          top: 2px;
        }
      }

      > svg {
        width: 100%;
        height: 100%;
      }
    }

    > .cnt {
      display: flex;
      margin-left: 5px;
    }
  }

  > .labelContent {
    &:before {
      content: "x";
      opacity: 0;
    }
  }

  &.smallSize{
    >.content{
      >.btn{
        width: 15px;
        height: 15px;
        padding: 0px;
      }
    }
  }

}
