.statisticsBox {
  position: relative;
  
  border-radius: 10px;
  box-shadow: 0px 1px 22px -12px #607D8B;
  background-color: #fff;
  overflow: scroll;
}

.statisticsTitle {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 900;
  font-weight: bold;
}

.remainingBox {
  height: 120px;
  width: 300px;
  margin: 30px;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 1px 22px -12px #607D8B;
  background-color: #fff;

  > .row > .column > .subtitle {
    display: flex;
    justify-content: center;
    text-align: center;
    color: #8c8e90;
    font-size: 12px;
    font-weight: 400;
  }

  .count {
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-size: 33px;
    font-weight: bold;
  }
}