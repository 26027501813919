.dateSelector {
  display: flex;
  flex-direction: column;
  position: relative;

  > .react-datepicker-wrapper {
    > .react-datepicker__input-container {
      display: flex;
      > input {
        width: 100%;
        border: none;
        outline: none;
        padding: 0px;
      }
    }
  }

  .react-datepicker-popper {
    margin-top: 5px !important;
    width: 200px;

    .react-datepicker {
      width: 100%;
      .react-datepicker__header {
        background: unset;

        > .react-datepicker__current-month {
          margin-bottom: 10px;
        }
      }
      .react-datepicker__triangle {
        display: none;
      }

      > .react-datepicker__month-container {
        width: 100%;

        .react-datepicker__day {
          width: 1.6rem;
          border-radius: 100%;
        }
      }
    }
  }
}
