body {
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
  -webkit-user-modify: read-only;
}

.input,
.dropdown,
.dateSelector,
.checkbox,
.textArea,
.fileUpload,
.colorPickerButton,
.phone {
  > .label {
    font-size: 12px;
    margin-bottom: 5px;
    margin-left: 10px;
  }

  > .error {
    margin-left: 10px;
    color: red;
    font-size: 12px;
    font-weight: bold;
    margin-top: 5px;
  }

  &.filter {
    > .label {
      font-size: unset;
    }
  }

  &.disabled {
    opacity: 1;
    .content,
    .react-datepicker__input-container {
      background: #e3e3e3;
      color: black;
      > input {
        background: inherit;
        color: inherit;
      }
      > textarea {
        background: inherit;
        color: inherit;
      }
    }
    .delete {
      opacity: 0;
    }
  }
}

.checkbox {
  > .label {
    margin-left: 0px;
  }
  &.disabled {
    .content {
      > .btn {
        opacity: 0.5;
      }
      background: unset;
    }
  }
}

.navbar{
  padding-left: 20px;
  padding-right: 20px;
}

.button {
  &.round {
    border-radius: 100%;
    width: 30px;
    height: 30px;
    justify-content: center;
    font-size: 10px;
  }

  &.roundBig {
    border-radius: 100%;
    width: 35px;
    height: 35px;
    justify-content: center;
    font-size: 15px;
  }

  &.iconLabel {
    > span {
      margin-left: 7px;
    }
  }
}

.input,
.colorPickerButton,
.react-datepicker-wrapper {
  > .content,
  > .react-datepicker__input-container {
    background: white;
    border: 1px solid rgb(177, 177, 177);
    box-sizing: border-box;
    border-radius: 10px;
    outline: none;
    font-size: 13px;
    color: rgb(128, 128, 128);

    > input {
      margin: 7px 10px;
    }
  }
}

.fileUpload {
  > .content {
    border-radius: 10px;
    border: 1px solid rgb(177, 177, 177);
  }
}

.filter {
  .label {
    font-weight: bold;
  }
}

::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: rgb(122, 122, 122);
}

* {
  scrollbar-color: rgb(122, 122, 122) rgba(0, 0, 0, 0);
}

.label {
  .required {
    &::before {
      color: red;
      content: "*";
      margin-left: 3px;
    }
  }
}

// RESETTERS

//Scrollbars
/* Firefox */
* {
  scrollbar-width: thin;
}

//Numeric inputs
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input:invalid {
  box-shadow: none !important;
}
