@import "../../../styles/mixins.scss";

.form {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
 
  &.scrollable {
    > .content {
      min-height: 210px;
      max-height: 100%;
      overflow-y: auto;
      position: relative;
    }
  }

  &.tabsMode {
    > .spinner {
      background: white;
    }
  }

  &.fullHeight {
    flex: 1;
  }

  > .content {
    display: flex;
    flex-direction: column;
    @include margin-items-vertical(10px);
    margin-top: 0px;
    flex: 1;

    .spinner{
      left: -10px;
      width: calc(100% + 10px);
    }

    &.noWrap {
      flex-flow: column;
    }
    
    > .row {
      flex-flow: row wrap;
      margin: 0px;
      > *:only-child.button.middle {
        margin-left: 45.3%;
      }
    }
  }

  > .actions {
    display: flex;
    margin-top: 10px;
    @include margin-items-horizontal(10px);
  }

  &.fullSize {
    > .content {
      > * {
        flex: 1;
      }
    }
  }
}
