.calendaravaibility {
    display: flex;
    flex-direction: column;
    position: inherit;
    z-index: 0;
    width: 100%;
    margin: 0px;
    padding: 0px;
    height: 480px;
}

.event {
    background-color: transparent;
    color: black;
    font-size: 15px;
    justify-content: center;
    align-self: center;
    text-align: center;

    .title {
        justify-content: center;
        font-size: 15px;

        &.outOfMonth {
            opacity: 50%;
        }
    }

    &.outOfMonth {
        opacity: 50%;
    }
}

.today {
    display: inline-flex;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    margin: auto;
    background-color: #236aa6;
    color: white;
    align-items: center;
    text-align: center;
    justify-content: center;

    &.outOfMonth {
        opacity: 50%;
    }
}

/* .dayfuture {
    background-color: lightgray;
} */
.day {
    width: 100%;
    height: 100%;
    background-color: white;;

    &.outofmonth {
        background-color: #f2f2f2;
    }
    &.cancelled {
        background-color: #ffd28f;
    }
    &.disabled {
        background-color: lightgray;
    }
    &.unavailable {
        background-color: #9e9e9e;
    }

    &.quotafull {
        background-color: #ba5959;
    }
    &.available {
        background-color: White;
    }
    &.booked {
        background-color: #65c97e;
    }
    &.backoffice {
        background-color: #ffb442;
    }
    &.outOfMonth {
        opacity: 50%;
    }
}

.calendar-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    min-height: 110px;
    margin-bottom: 10px;
    background: transparent;
  }

 .calendar-spinenr {
    display: flex;
    justify-self: center;
    align-self: center;
    background: transparent !important;
  }
