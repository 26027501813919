$ruler: 16px;
.toolbar {
    display: flex;
    padding: 20px;
    
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    overflow: hidden;
  }

  .labelCalendar {
    font-size: $ruler;
    border-radius: $ruler / 2;
    padding: $ruler;
    box-shadow:  inset 5px 5px 10px #cbcbcb, 
    inset -5px -5px 10px #ffffff;
  }
  