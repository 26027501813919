.horizontal {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 20px;
    margin-left: 20px;
}

.verticalIn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 250px;
    width: 100%;
}

.logo{
    height: 75px;
  }

.error {
    margin-left: 10px;
    color: red;
    font-size: 12px;
    font-weight: bold;
    margin-top: 5px;
  }