.bold {
  display: flex;
  flex-direction: column;
  font-size: 25px;
  align-items: flex-start;
  width: auto;
  height: auto;
  margin-top: 5px;
  font-weight: bold;

  &.big {
    font-size: 22px;
  }
  &.medium {
    font-size: 18px;
  }
  &.normal {
    font-size: 15px;
  }
  &.small {
    font-size: 12px;
  }
}

.none {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  align-items: flex-start;
  width: 100%;
  height: auto;
  margin-top: 5px;
  font-weight: bold;

  &.big {
    font-size: 22px;
  }
  &.medium {
    font-size: 18px;
  }
  &.normal {
    font-size: 15px;
  }
  &.small {
    font-size: 12px;
  }
}