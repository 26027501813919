.button {
  display: flex;
  //@function: pointer;
  outline: none;
  background: unset;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */

  > span {
    display: flex;
    flex-direction: column;
  }

  .label {
    display: flex;
    flex-direction: column;

    &.content {
      padding-left: 10px;
    }
  }

  > .tooltiHolder {
    opacity: 0;
    position: relative;
    width: 100%;
    height: 100%;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .spinContainer {
    position: absolute;
    display: flex;
    margin-left: 10px;
    height: 20px;
    width: 20px;
    align-items: center;
    justify-content: center;

    /*You can increase or decrease the timer (5s) to 
     increase or decrease the speed of the spinner*/
  }

  .spinner {
    background-color: transparent;
    animation: spin infinite 1s linear;

    /*You can increase or decrease the timer (5s) to 
     increase or decrease the speed of the spinner*/
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
