switch {
    position: "relative";
    display: "inline-block";
    text-align: "left";
    direction: ltr;
    border-radius: 50%;
    -webkit-transition: "opacity 0.25s";
    -moz-transition: "opacity 0.25s";
    transition: "opacity 0.25s";
    touch-action: none;
    -webkit-tap-highlight-color: "rgba(0, 0, 0, 0)";
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.switch-content-background {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
}

.icon-logo {
    font-size: 15px;
    flex: 1;
    flex-grow: 1;
    margin-bottom: 1px;
    color: white;
    text-align: center;
    vertical-align: center;
}