@import "../../../styles/mixins.scss";

.tabs {
  display: flex;
  flex-direction: column;
  flex: 1;

  > .header {
    display: flex;
    flex-flow: row wrap;
    flex: 1;
    border-bottom: 1px solid rgb(189, 189, 189);
    padding: 0px 0px;
    @include margin-items-horizontal(5px);
    padding-bottom: 5px;

    > .button {
      border: unset;
      margin-bottom: 10px;
      padding: 0px 10px;
      padding-bottom: 3px;

      &.active {
        font-weight: bold;
        border-bottom: 1px solid green;
      }
    }
  }

  > .content {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
