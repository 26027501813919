.fileUpload {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 100px;
  height: 200px;
  background: white;
  cursor: pointer;
  flex: 1;

  &.fullSize {
    flex: 1;
  }

  &.midSize {
    flex: 0.5;
  }

  > input {
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    cursor: pointer;
  }

  > .content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    flex-direction: column;
    width: 100%;
    height: 100%;
    pointer-events: none;

    > svg {
      width: 45%;
      height: 45%;
    }

    > .label {
      margin-top: 25px;
      color: #2ea591;
      font-size: 16px;
      font-weight: bold;
    }

    >.name{
      font-weight: bold;
      max-width: 50%;
      text-align: center;
    }
  }

  > .error {
    text-align: center;
    position: relative;
    top: -10px;
  }

  > .success {
    color: #2ea591;
    text-align: center;
    position: relative;
    top: -10px;
  }
}
