.pageWrapper {
  display: flex;
  flex-direction: column;

  .pageContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
