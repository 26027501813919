.radioGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  margin: 5px;

  &.label {
    justify-content: center;
    align-items: center;
  }

  &.midSize{
    padding: 10px 25px;
  }

  &.checked {
    > .content {
      > .btn {
        background: #66615b;
      }
    }
  }
  
  .error {
    margin-left: 10px;
    color: red;
    font-size: 12px;
    font-weight: bold;
    margin-top: 5px;
  }
}
