.linkButton {
  border: unset;
  padding: 0px;
  border-radius: 30px;
  font-weight: bold;
  color: #236aa6;

  &.label {
    justify-content: center;
    align-items: center;
  }

  &:hover {
    text-decoration: underline;
  }
}
