.navbarContentItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: rgb(51, 51, 51);

  &.active{
    font-weight: bold;
    text-decoration: underline;
    color: black;
  }

  &:hover {
    text-decoration: underline;
  }
  
}
