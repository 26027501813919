.AnimationStatus {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;

  .tooltiptext {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0s;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
   
    /* Position the tooltip text - see examples below! */
    /* https://www.w3schools.com/css/css_tooltip.asp#:~:text=Basic%20Tooltip&text=HTML%3A%20Use%20a%20container%20element,with%20class%3D%22tooltiptext%22%20. */
    position: absolute;
    z-index: 1;
    top: 1px;
    right: 105%;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 100%;
      margin-top: -5px;
      margin-right: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent transparent #555;
    }
  }

  &:hover {
    .tooltiptext {
      visibility: visible;
      opacity: 1;
      transition: opacity 0s 0.5s;
    }
  }
}
