@import "styles/mixins.scss";

.popup {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  z-index: 9000;
  align-items: center;
  justify-content: center;
  margin: 0px !important;
  padding: 0px !important;

  // &.bigSize {
  //   > .content {
  //     width: 70%;
  //   }
  // }

  &.bottom {
    position: relative;
    width: 100%;
    height: 0px;
    >.content{
      width: 100%;
      transform: translateY(-50%);
      box-shadow: unset;
      border: 1px solid rgb(199, 199, 199);
      margin: 0px 10px;
      >.items{
          padding: 0px;
      }
    }
  }

  > .background {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba($color: #424242, $alpha: 0.4);
  }

  .spinner {
    > .content {
      top: unset !important;
    }
    > .footer {
      top: unset !important;
    }
  }

  &.midSize {
    > .content {
      width: 860px;
    }
  }

  &.middle {
    > .content {
      top: 0px;
    }
  }

  > .content {
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 10px 10px 60px #555;
    border-radius: 10px;
    min-width: 100px;
    min-height: 150px;
    max-height: 95vh;
    margin-bottom: 5vh;
    width: 560px;

    > .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 20px;
      font-size: 20px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      > .title {
        display: flex;
        align-items: center;
        > .icon {
          display: flex;
          align-items: center;
          margin-right: 10px;
        }
      }

      > .btnClose {
        border: none;
        color: white;
        &::before {
          content: "x";
          font-size: 20px;
          font-weight: bold;
        }
      }
    }

    > .items {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 20px;
    }

    > .scrollableItems {
      display: flex;
      flex: 1;
      padding: 20px;
      padding-right: 10px;
      overflow-y: scroll;
    }

    > .footer {
      position: absolute; 
      bottom: 0px;
      left: 0px;
      background-color: transparent;
      border: none;
    }
  }
}
