@import "../../../styles/mixins.scss";

.row {
  display: flex;
  position: relative;
  @include margin-items-horizontal(5px);

  &.hidden{
    opacity: 0;
  }

  &.fullSize {
    width: 100%;
  }

  &.spaceBetween {
    justify-content: space-between;
  }

  &.center {
    justify-content: center;
  }

  &.middle {
    align-items: center;
  }

  &.overflowX {
    overflow-x: auto;
  }

  &.fullHeight {
    flex: 1;
  }

  &.midSize {
    flex: 2;
  }

  &.right {
    justify-content: flex-end;
    justify-self: flex-end;
    justify-items: flex-end;
    align-items: flex-end;
    align-self: flex-end;
    align-content: flex-end;
    margin-left: auto;
  }

  *:only-child {
    &.button.middle {
      margin: auto;
    }
  }

  &.minHeight{
    min-height: 170px;
  }

  &.medHeight{
    min-height: 370px;
  }
}
