.dayagenda {
    display: flex;
    height: 100px;
    width: 100px;
    position: relative;
    flex-direction: column;
    border: 1px solid #CDCDCD;
    border-radius: 10px;
    background: white;
    position: relative;
    align-items: center;
    overflow: hidden;
    box-shadow:  5px 5px 10px #cbcbcb, 
    -5px -5px 10px #ffffff;
}

.headerday {
    display: flex;
    justify-content: center;
    background: #CDCDCD;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-items: center;
}

.month {
    display: flex;
    font-size: 18px;
    color: #D90411;
    font-weight: bold;
    text-align: center;
    align-self: center;
    justify-self: center;
}
  
.weekday {
    height: 33%;
    font-size: 18px;
    font-weight: bold;
    justify-self: center;
}

.monthday {
    height: 66%;
    font-size: 35px;
    font-weight: bold;
    justify-self: center;
}