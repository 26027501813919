.footer{
    display: flex;
    position: fixed;
    border-top: 1px solid rgb(218, 218, 218);
    padding: 15px 10px;
    bottom: 0px;
    width: 100%;
    color: rgb(124, 124, 124);
    background: #fafafa;
    z-index: 100;
}