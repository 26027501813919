@import "../../../styles/theme.scss";

.checkbutton {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 900;
  margin: 12px 20px;
  padding: 12px 20px;
  font-size: 1rem;
  border-radius: 3.5rem / 100%;
  position: relative;
  min-width: 15rem;
  max-width: 90vw;
  overflow: hidden;
  border: 0;
  cursor: pointer;
  letter-spacing: 0.05em;
  transition: all $time;

  box-shadow: -5px -5px 10px $color-white,  5px 5px 10px $color-shadow;
  &:active {
    box-shadow: inset 1px 1px 2px $color-shadow, inset -1px -1px 2px $color-white;
  }
  
  & + & {
    margin-top: 1rem;
  }
  
  span {
    position: relative;
    z-index: 1;
  }
}