.table {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  z-index: 0;
  height: 100%;
  max-height: 75vh;
  margin-top: 10px;
  //border-top: 1px solid rgb(224, 224, 224);
  box-sizing: border-box;

  > .table-content {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    position: relative;
    overflow-y: auto;
    min-height: 200px;
    pointer-events: auto;
  }

  &.minHeight {
    > .table-content {
      min-height: 250px;
    }
  }

  &.medHeight {
    > .table-content {
      padding-bottom: 10px;
      min-height: 350px;
    }
  }

  .spinner {
    background: rgba($color: #f2f2f2, $alpha: 0.5);

    > .content {
      top: 20px !important;
    }
  }
  table {
    flex: 1;
    width: 100%;
    //border-collapse: collapse;
    text-align: left;
    overflow-x: auto;
    thead {
      th {
        background: #ffffff;
        position: sticky;
        top: 0px;
        z-index: 1;
        padding: 5px 10px;
        vertical-align: 0%;
        white-space: nowrap;

        > .headerLabel {
          display: flex;
          align-items: center;

          > .actions {
            display: flex;
            margin-left: 0px;
          }

          &.sortable {
            cursor: pointer;
          }

          &.hidden {
            opacity: 0;
          }

          > .sort {
            margin-left: 5px;
            cursor: pointer;
          }
        }

        > .filter,
        .headerComponent {
          width: unset !important;
          margin-top: 10px;
        }
      }
    }

    td,
    tr,
    th {
      padding: 4px 5px;
      //border-left: 1px solid rgb(224, 224, 224);
      border-right: 1px solid rgb(224, 224, 224);
      border-bottom: 1px solid rgb(224, 224, 224);
    }

    tr {
      background: white;
      &.clickable {
        cursor: pointer;
        &:hover {
          background: rgb(0, 175, 236) !important;
        }
      }
      &.selected {
        background: rgb(0, 175, 236) !important;
        color: white !important;
      }
      &.fontWeightBold {
        font-weight: bold;
      }
    }

    tbody {
      height: 1px;
      tr:nth-child(odd) {
        background: rgb(243, 243, 243);
      }
    }
  }


  .spinner {
    pointer-events: auto;
  
    > .content {
      top: 20px !important;
    }
  }
  
  .spinContainer {
    position: absolute;
    display: flex;
    height: 100px;
    width: 100px;
    position: absolute;
    left: 50%;
    margin-left: -30px;
    background: transparent;
  }
  
  .spinner {
    height: 60px;
    width: 60px;
    animation: spin infinite 1s linear;
    background: transparent;
    color: #2a72b1;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
