.headerDashBoard {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    width: 100%;
//
}

.center {
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    width: 100%;
    padding: 0;
    margin: 0;
}

.horizontal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    margin: 0;
}

.vertical {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    margin: 5px;
}