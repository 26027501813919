@mixin margin_items_horizontal($m) {
  > *:first-child {
    margin-right: $m * 0.5;
  }
  > *:last-child {
    margin-left: $m * 0.5;
  }

  > *:not(:first-child):not(:last-child) {
    margin-left: $m * 0.5;
    margin-right: $m * 0.5;
  }
  > *:only-child {
    margin-left: 0px;
    margin-right: 0px;
  }
}

@mixin margin_items_vertical($m) {
  > *:first-child {
    margin-bottom: $m * 0.5;
  }
  > *:last-child {
    margin-top: $m * 0.5;
  }
  > *:not(:first-child):not(:last-child) {
    margin-top: $m * 0.5;
    margin-bottom: $m * 0.5;
  }
  > *:only-child {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}