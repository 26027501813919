@import "../../../styles/mixins.scss";

.hamburgerButton {
  flex-direction: column;
  border: unset;
  @include margin_items_vertical(3px);
  align-items: center;
  background: rgba($color: white, $alpha: 0.05);
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgb(221, 221, 221);;

  > * {
    display: flex;
    background: rgb(155, 154, 154);
    height: 2px;
    width: 15px;
  }
}
