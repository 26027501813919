@import "../../../styles/mixins.scss";

.column {
  display: flex;
  flex-direction: column;
  @include margin-items-vertical(10px);

  &.midSize {
    flex: 0.5;
  }

  &.fullSize {
    width: 100%;
  }

  &.center {
    align-content: center;
    align-items: center;
    align-self: center;
    justify-content: center;
    justify-items: center;
    justify-self: center;
  }

  &.left {
    align-items: flex-start;
  }

  &.right {
    align-items: flex-end;
  }

  &.scrollable {
    max-height: 50vh;
    overflow-y: scroll;
  }
}
