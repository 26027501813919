@import "../../styles/mixins.scss";

.pageContent {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  padding: 3px 10px;
  overflow: visible;
  position: relative;
  margin-top: 40px;
  margin-bottom: 40px;

  &.inTabs {
    > .content {
      padding-left: 0px;
    }
  }

  > .overlay {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 1;
    height: 100%;
    flex: 1;
    width: 100%;
  }

  > .header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    > .title {
      display: flex;
      flex-direction: row;
      font-size: 22px;
      align-items: center;
      font-weight: bold;

      > .titleContent {
        display: flex;
        @include margin-items-horizontal(10px);
      }

      > .backButton {
        margin-right: 15px;
      }
    }

    > .actions {
      display: flex;
      width: 100%;
      @include margin-items-horizontal(10px);
    }
  }

  > .content {
    display: flex;
    position: relative;
    flex: 1;
    flex-direction: column;
    @include margin-items-vertical(10px);

    .spinner {
      margin-top: 0px;

      > .content {
        top: 100px;
      }
    }

    > .row {
      @include margin-items-horizontal(10px);
    }

    // .dropdown,
    // .input,
    // .textArea,
    // .dropdown,
    // .dateSelector,
    // .sameWidth {
    //   width: 250px;
    // }

    .sameWidth {
      margin-right: 20px !important;
    }
  }

  &.tabsMode {
    border-radius: 20px;

    > .content {
      padding-left: 0px;
    }
  }
}
