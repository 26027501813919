.textArea {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  align-items: center;
  width: 100%;
  height: 85px;
  margin-top: 5px;
  align-items: flex-start;

  > .content {
    display: flex;
    align-items: center;
    width: 100%;
    > textarea {
      border: 1px solid rgb(177, 177, 177);
      padding: 6px 15px;
      padding-right: 10px;
      box-sizing: border-box;
      border-radius: 10px;
      width: 100%;
      height: 100%;
      outline: none;
      overflow-y: auto;
      resize: none;
    }

    &.disabled {
      opacity: 1;
      > textarea {
        background: #e3e3e3;
        color: black;
      }
    }
  }
}
