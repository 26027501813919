.spinner {
  width: 100%;
  height: 100%;
  background: white;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  z-index: 5;

  &.relative{
    >.background{
      width: 100%;
      height: 100%;
    }
  }

  &.micro{
    background: #fefeff;
    .content{
      top: 10px !important;
      width: 20px;
      height: 20px;
      border: 5px solid #e9e9e9;
      border-top: 5px solid #3498db;
    }
  }

  > .background {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
  }

  > .content {
    position: absolute;
    border: 10px solid rgb(233, 233, 233);
    border-top: 10px solid #3498db;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
  }

  &.mini{
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%,-50%);

    >.content{
      border: 3px solid rgb(233, 233, 233);
      border-top: 3px solid #3498db;
      width: 20px;
      height: 20px;
      position: fixed;
      top: unset !important;
      left: unset !important;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
