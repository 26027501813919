@import "../../styles/mixins.scss";

.navbar {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(218, 218, 218);
  padding: 10px;
  position: fixed;
  z-index: 1;
  width: 100%;
  color: rgb(124, 124, 124);
  background: #fafafa;

  >.logo{
    cursor: pointer;
    height: 30px;
  }

  >.actions{
    display: flex;
    @include margin_items_horizontal(10px);
    margin-left: 30px;
  }

  >.info{
    display: flex;
    margin-left:  auto;
    margin-right: 20px;
  }

  >.actions2{
    margin-left: auto;
    margin-right: 20px;
    display: none;
  }

  
}

// @media (orientation: portrait) {
//   .navbar {
//     >.actions{
//       display: none;
//     }

//     >.info{
//       display: flex;
//       width: 100%;
//       justify-content: flex-end;
//     }

//     >.actions2{
//       display: flex;
//     }
//   }
// }
