$ruler: 16px;
$time: 300;
$color-shadow: #BABECC;
$color-white: #FFF;

$font-family: Tahoma;
$color-shadow-light: #FFF;
$color-shadow: #e0e4e5;
$color-background: #f8f8f8;

$color-white: #FFF;
$color-light-white: #f8f8f8;
$color-white-smoked: #d8dfe8;
$color-blue: #00529C;
$color-green: #28864e;
$color-red: #C50000;
$color-orange: #ec8626;
$color-yellow: #FAA61A;
$color-pink: #C51260;
$color-violet: #73295c;
$color-gray: #66615b;
$color-light-blue: #00ABDF;
$color-light-green: #50B848;

$sizes: (
  small: 8px,
  medium: 12px,
  normal: 14px,
  large: 16px,
  big: 18px
);

$btns: (
  purple: (
    backgroundToggled: $color-violet,
    backgroundUntoggled: $color-light-white,
    labelToggled: $color-light-white,
    labelUntoggled: $color-violet,
    borderToggled: $color-violet,
    borderUntoggled: $color-violet
  ),
  red: (
    backgroundToggled: $color-red,
    backgroundUntoggled: $color-light-white,
    labelToggled: $color-light-white,
    labelUntoggled: $color-red,
    borderToggled: $color-red,
    borderUntoggled: $color-red
  ),
  blue: (
    backgroundToggled:$color-blue,
    backgroundUntoggled: $color-light-white,
    labelToggled: $color-light-white,
    labelUntoggled: $color-blue,
    borderToggled: $color-blue,
    borderUntoggled: $color-blue
  ),
  green: (
    backgroundToggled: $color-green,
    backgroundUntoggled: $color-light-white,
    labelToggled: $color-light-white,
    labelUntoggled: $color-green,
    borderToggled: $color-green,
    borderUntoggled: $color-green
  ),
  orange: (
    backgroundToggled: $color-orange,
    backgroundUntoggled: $color-light-white,
    labelToggled: $color-light-white,
    labelUntoggled: $color-orange,
    borderToggled: $color-orange,
    borderUntoggled:$color-orange
  ),
  gray: (
    backgroundToggled: $color-gray,
    backgroundUntoggled: $color-light-white,
    labelToggled: $color-light-white,
    labelUntoggled: $color-gray,
    borderToggled: $color-gray,
    borderUntoggled:$color-gray
  )
);

@each $bsizename, $bsize in $sizes {
  .size--#{$bsizename} {
    font-size: $bsize;
    border-radius: $bsize / 2;
    padding: $bsize;

    &.rounded {
      border-radius: $bsize*20;
    }
  }
}

@each $bname, $bcolors in $btns {
  .circlebtn--#{$bname} {
    background-color: map-get($bcolors, labelToggled);
    color: map-get($bcolors, backgroundToggled);
    border-color: map-get($bcolors, borderToggled);

    &:hover {
      background-color: map-get($bcolors, backgroundToggled);
      color: map-get($bcolors, labelToggled);
    }
    
    &:active {
      background-color: map-get($bcolors, backgroundToggled);
      color: map-get($bcolors, labelToggled);
    }
  }

  .neurobtn--#{$bname} {
    background-color: map-get($bcolors, backgroundToggled);
    color: map-get($bcolors, labelToggled);
    border-color: map-get($bcolors, borderToggled);
  }

  .checktoggled--#{$bname} {
    background-color: map-get($bcolors, backgroundUntoggled);
    color: map-get($bcolors, labelUntoggled);
    border-color: map-get($bcolors, borderUntoggled);
  }

  .checkbtn--#{$bname} {
    background-color: map-get($bcolors, backgroundToggled);
    color: map-get($bcolors, labelToggled);
    border-color: map-get($bcolors, borderToggled);
  }
}

body {
  background: #f2f2f2;
  color: #2c2c2c;
}

.sidebar {
  > .reactSidebar {
    > .sidebar {
      background: linear-gradient(#66615b 0%, #000 80%) !important;
    }
  }
}

.navbar {
  background: white;
}

.button {
  &.langButton {
    color: #2267a2;

    // &.active {
    //   background: #2267a2;
    //   color: white;
    // }
  }

  &.myAccountButton {
    background-image: linear-gradient(to right, #6c2472 0%, #4b4389 51%, #2963a0 100%);
    color: white;
  }

  &.regenerateButton {
    background-image: linear-gradient(to right, #6c2472 0%, #4b4389 51%, #2963a0 100%);
    color: white;
  }

  &.localButton {
    background: #236aa6;
    color: white;
  }

  &.tabButton {
    border: 1px solid #236aa6;
    color: rgb(139, 139, 139);

    &:hover {
      background: #236aa6;
      color: white;
    }

    &.active {
      background: #236aa6;
      color: white;
    }
  }

  &.sendButton {
    border: 2px solid $color-green;
    color: $color-green;

    &:hover {
      background: $color-green;
      color: white;
    }
  }

  &.leftArrowButton {
    border: 2px solid $color-green;
    color: $color-green;
    font-size: 40px;

    &:hover {
      background: $color-green;
      color: white;
    }
  }

  &.rightArrowButton {
    border: 2px solid $color-green;
    color: $color-green;
    font-size: 40px;

    &:hover {
      background: $color-green;
      color: white;
    }
  }

  &.exportButton {
    background: #66615b;
    color: white;
  }

  &.saveButton {
    background: $color-green;
    color: white;
  }

  &.activateButton {
    background: $color-green;
    color: white;
  }

  &.deleteButton {
    background: $color-orange;
    color: white;
  }

  &.deactivateButton {
    background: $color-orange;
    color: white;
  }

  &.deleteConfirmationButton {
    background: $color-orange;
    color: white;
  }

  &.pencilButton {
    border: 2px solid #6e2170;
    color: #6e2170;

    &:hover {
      background: #6e2170;
      color: white;
    }
  }

  &.plusButton {
    border: 2px solid $color-green;
    color: $color-green;

    &:hover {
      background: $color-green;
      color: white;
    }
  }

  &.filterButton {
    background: #236aa6;
  }

  &.removeButton {
    border: 2px solid $color-orange;
    color: $color-orange;
    font-size: 40px;

    &:hover {
      background: $color-orange;
      color: white;
    }
  }

  &.downloadButton {
    border: 2px solid #66615b;
    color: #66615b;
    font-size: 40px;

    &:hover {
      background: #66615b;
      color: white;
    }
  }

  &.validateButton {
    border: 2px solid $color-green;
    color: $color-green;
    font-size: 40px;

    &:hover {
      background: $color-green;
      color: white;
    }
  }

  &.profileButton {
    border: 2px solid #51bcda;
    color: #51bcda;
    font-size: 40px;

    &:hover {
      background: #51bcda;
      color: white;
    }
  }

  &.powerButton {
    background: $color-orange;

    &.started {
      background: $color-green;
    }
  }

  &.defaultButton {
    border: 2px solid #51bcda;
    color: #51bcda;

    &.default {
      background: #51bcda;
      color: white;
    }

    &:hover {
      background: #51bcda;
      color: white;
    }
  }

  &.viewButton {
    border: 2px solid #51bcda;
    color: #51bcda;
    svg {
      > * {
        stroke: #51bcda;
        fill: #51bcda;
      }
    }

    &:hover {
      background: #51bcda;
      color: white;
      svg {
        > * {
          stroke: white !important;
          fill: white !important;
        }
      }
    }
  }

  &.saveRoundButton {
    border: 2px solid $color-green;
    color: $color-green;
    font-size: 40px;

    &:hover {
      background: $color-green;
      color: white;
    }
  }

  &.selectButton {
    border: 2px solid #6e2170;
    color: #6e2170;

    &:hover {
      background: #6e2170;
      color: white;
    }
  }

  &.pinButton {
    border: 2px solid #6e2170;
    color: #6e2170;

    &:hover {
      background: #6e2170;
      color: white;
    }
  }

  &.commentsButton {
    border: 2px solid $color-green;
    color: $color-green;

    &:hover {
      background: $color-green;
      color: white;
    }
  }

  &.searchButton {
    background: #6e2170;
    color: white;
  }

  &.resetButton {
    background: #66615b;
    color: white;
  }

  &.calendarButton {
    border: 2px solid #6e2170;
    color: #6e2170;

    &:hover {
      background: #6e2170;
      color: white;
    }
  }

  &.blacklistButton {
    border: 2px solid $color-orange;
    color: $color-orange;

    &:hover {
      background: $color-orange;
      color: white;
    }
  }
}

.popup {
  > .content {
    background: #fcfcfc;
    > .header {
      color: white;
      background: $color-blue;
    }
  }
}

.table {
  overflow: hidden;
  border-radius: 10px;
  box-shadow:  5px 5px 10px #cbcbcb, 
  -5px -5px 10px #ffffff;
}

.input {
  > .content {
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow:  2px 2px 5px #cbcbcb, 
    -2px -2px 5px #ffffff;

    > input {
      background-color: #FFFFFF;
    }
  }
}

.dateSelector {
  > .react-datepicker-wrapper {
    background-color: #FFFFFF;
      border-radius: 10px;
      box-shadow:  2px 2px 5px #cbcbcb, 
      -2px -2px 5px #ffffff;
    > .react-datepicker__input-container {
      > input {
        background-color: #FFFFFF;
      }
    }
  }
}

.textArea {
  > .content {
    > textarea {
      background-color: #FFFFFF;
      border-radius: 10px;
      box-shadow:  2px 2px 5px #cbcbcb, 
      -2px -2px 5px #ffffff;
    }
  }
}

.phone{
  >.phone-content{
      input{
        background-color: #FFFFFF;
        border-radius: 10px;
        box-shadow:  2px 2px 5px #cbcbcb, 
        -2px -2px 5px #ffffff;
      }
  }
}

.sidebarContent {
  .sidebarContentItem {
    color: #bdbcbb;

    &.active {
      color: #51bcda;
    }

    > .collapsible {
      .trigger {
        background: unset;
      }
    }
  }
}
