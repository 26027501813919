.title {
  display: flex;
  flex-direction: column;
  font-size: 25px;
  align-items: flex-start;
  width: 100%;
  height: auto;
  font-weight: bold;
}

.field {
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 99%;
  flex-basis: auto;
  height: auto;

  &.visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.5s linear;
  }
  &.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.5s, opacity 0.5s linear;
  }
}

.fieldheader{
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}

.icon {
  font-size: 1.0em;
  vertical-align: middle;
  margin-right: 10px;
  padding-top: 15px;
  scale: 2.0;
}

.title {
  display: flex;
  flex-direction: row;
  font-size: 22px;
  width: 100%;
  align-self: center;
  justify-self: center;
  font-weight: bold;

  &.big {
    font-size: 22px;
  }
  &.medium {
    font-size: 18px;
  }
  &.normal {
    font-size: 15px;
  }
  &.small {
    font-size: 12px;
  }
}

.box {
    border-radius: 10px;
    width: 100%;
    height: auto;
    outline: none;
    resize: inline;

    .table {
      margin-top: 0px;
    }

    &.spacing {
      margin-top: 10px;
    }

    &.border {
      //border: 1px solid rgba(230, 230, 230, 0.8);
      box-shadow:  5px 5px 10px #cbcbcb, 
      -5px -5px 10px #ffffff;
    }
}
