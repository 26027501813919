.phone{
    display: flex;
    flex-direction: column;
  
    >.phone-content{
        input{
            width: 250px;
            border-radius: 10px !important;
            background: white !important;
            border: 1px solid #b1b1b1 !important;
            outline: none !important;
            font-size: 13px !important;
            color: gray !important;
            height: 31px !important;
        }
    }
}